import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../../../gatsby-config';
import {Button} from "@blueprintjs/core";
import {AnchorLink} from "gatsby-plugin-anchor-links";

// Images
import biuwerKitDigitalPortada from '../../../../static/img/kit-digital/biuwer-kit-digital-portada.png';
import kitDigitalLogoDigitalizadores from '../../../../static/img/kit-digital/kit-digital-logo-digitalizadores.png';
import biuwerKitDigitalPackEcommerce from '../../../../static/img/kit-digital/biuwer-kit-digital-pack-ecommerce.png';
import biuwerKitDigitalPackPyme from '../../../../static/img/kit-digital/biuwer-kit-digital-pack-pyme.png';

// Components
import MainLayout from '../../../components/main-layout';

const KitDigitalPage = (props) => {
    const { pageContext } = props,
        siteUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : config.siteMetadata.siteUrl,
        img = biuwerKitDigitalPortada,
        imgUrl = `${siteUrl}${img}`;

    return (
        <MainLayout pageContext={pageContext}>
            <Helmet>
                <meta property="og:image" content={imgUrl} />
                <meta name="twitter:image" content={imgUrl} />
            </Helmet>
            <div className="container pt40" style={{ marginTop: 72 }}>
                <div className="row mv40">
                    <div className="col-sm-12 col-lg-5 mb20">
                        <h1 className="mb30">Consigue tu Kit Digital y toma el control de tu empresa usando Business Intelligence</h1>
                        <h5 className="mb30" style={{ fontWeight: 400, lineHeight: '30px' }}>Analizar tus datos con Biuwer puede salirte gratis, gracias a las nuevas ayudas de hasta 12.000€ que ofrece Red.es</h5>
                        <div className="mv10" style={{ color: '#666' }}>100% basado en la nube, ¡siempre actualizado!</div>
                        <div className="mv10" style={{ color: '#666' }}>Fácil de usar, ¡sin conocimientos técnicos!</div>
                        <div>
                            <AnchorLink
                                to="/es/lp/kit-digital/#estoy-interesado"
                                title="Estoy Interesado"
                                style={{ textDecoration: 'none' }}
                            >
                                <Button
                                    text="ESTOY INTERESADO"
                                    intent="success"
                                    style={{ boxShadow: 'none', marginTop: 20, marginBottom: 20 }}
                                    large={true}
                                />
                            </AnchorLink>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-7 mb20">
                        <img style={{ width: '90%' }}
                             src={biuwerKitDigitalPortada}
                             alt={'Consigue tu Kit Digital, Business Intelligence y Analítica'}
                        />
                    </div>
                </div>
            </div>
            <div className="container" style={{ padding: '20px 10px' }}>
                <div className="row mb30">
                    <div className="col-12">
                        <h2 className="mb20">¿Qué es el Kit Digital?</h2>
                        <h5>El programa&nbsp;
                            <a
                                href={`https://www.acelerapyme.gob.es/kit-digital`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >Kit Digital</a>
                            &nbsp;es una iniciativa que subvenciona la implantación de soluciones digitales para mejorar el nivel de madurez digital de las empresas.
                        </h5>
                        <p>Los aspectos más destacados son:</p>
                        <ul>
                            <li>Cada empresa dispone de un bono digital, que dependiendo de su tamaño, puede llegar hasta los 12.000€ para invertirlos en varias soluciones.</li>
                            <li>Tu empresa dispone como máximo de 4.000€ para implantar un proyecto de Business Intelligence y Analítica.</li>
                            <li>Las ayudas para el Kit Digital se conceden por orden de llegada. Si estás interesado contáctanos cuanto antes.</li>
                        </ul>
                    </div>
                </div>
                <div className="row mb30">
                    <div className="col-12" style={{ textAlign: 'center' }}>
                        <img
                            src={kitDigitalLogoDigitalizadores}
                            alt={'Consigue tu Kit Digital, Business Intelligence y Analítica'}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            </div>
            <div className="container" style={{ padding: '20px 10px' }}>
                <div className="row mb30">
                    <div className="col-12">
                        <h2 className="mb20">¿Qué puedo conseguir con Biuwer?</h2>
                        <h5>Como expertos en Business Intelligence y Big Data, te asesoramos en todo el proceso e implantamos tu proyecto de la línea&nbsp;
                            <a
                                href={`https://www.acelerapyme.gob.es/kit-digital/bi-analitica`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >Business Intelligence y Analítica</a>
                            &nbsp;del programa Kit Digital.
                        </h5>
                        <p>Beneficios de usar una solución de Business Intelligence:</p>
                        <ul>
                            <li><b>Ahorro de tiempo, porque los datos se centralizan y actualizan automáticamente</b>. Con Biuwer no es necesario acceder una por una a las herramientas, realizar exportaciones de datos manuales y montar los informes cada vez que se quieras analizar los datos.</li>
                            <li><b>Decisiones casi en tiempo real</b>. Resolver problemas lo antes posible puede suponer grandes cantidades de dinero para tu empresa. Con Biuwer sabrás lo ocurre en tu empresa rápidamente y podrás actuar de inmediato. Nada de esperar a acabar el mes o el trimestre, cada día o cada hora puede suponer una gran diferencia.</li>
                            <li><b>Mejora de las cifras de negocio</b>, gracias a tener un conocimiento detallado de lo que ocurre en tu empresa. Tomar decisiones rápidas con certeza y con datos exactos supone mejorar los márgenes, disminuir costes y aumentar las ventas.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container" style={{ padding: '20px 10px' }}>
                <div className="row mb30">
                    <div className="col-12">
                        <h2 className="mb20">Soluciones disponibles</h2>
                        <h5>Hemos preparado dos packs, selecciona el tuyo y ponte en contacto para más información.</h5>
                    </div>
                </div>
                <div className="row mb30">
                    <div className="col-12 col-xl-6">
                        <h3>Pack Ecommerce</h3>
                        <p>Si tienes una tienda online con <b>WooCommerce o Prestashop</b>, tenemos una solución para tí con los <b>KPIs principales de tu ecommerce</b>. Podrás hacer cualquier pregunta a los datos, incluyendo integración con otras herramientas, como <b>Google Analytics, redes sociales y otras herramientas de Marketing.</b></p>
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                            <img
                                src={biuwerKitDigitalPackEcommerce}
                                alt={'Pack Ecommerce, para tiendas online WooCommerce o Prestashop'}
                                style={{ width: '90%', maxWidth: 400 }}
                            />
                        </div>
                        <p>Incluye:</p>
                        <ul>
                            <li>Análisis de las necesidades de tu empresa.</li>
                            <li><b>Automatización de datos</b> provenientes de tu tienda online y de las herramientas digitales que utilizas. <b>Preparación de KPI´s</b> adecuados a tus necesidades.</li>
                            <li>Montaje de <b>informes y dashboards</b> en Biuwer, incluyendo filtros de análisis interactivo.</li>
                            <li><b>Informe periódico con recomendaciones de alto valor</b>, realizado por nuestros analistas de datos.</li>
                        </ul>
                    </div>
                    <div className="col-12 col-xl-6">
                        <h3>Pack PYME</h3>
                        <p>Para PYMEs que utilicen <b>herramientas de gestión (ERPs, CRMs)</b>, de gestión de proyectos, que dispongan de aplicaciones personalizadas con <b>bases de datos</b>, con datos también almacenados en <b>ficheros Excel o CSV</b>, etc. hacemos toda la preparación de datos y montaje de tu <b>sistema Business Intelligence personalizado</b> a tu empresa.</p>
                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                            <img
                                src={biuwerKitDigitalPackPyme}
                                alt={'Pack PYME, para empresas que quieren mejorar'}
                                style={{ width: '90%', maxWidth: 400 }}
                            />
                        </div>
                        <p>Incluye:</p>
                        <ul>
                            <li>Análisis de las necesidades de tu empresa.</li>
                            <li><b>Conexión con las fuentes de datos</b> y definición de los <b>modelos de datos</b> (dimensiones de análisis, KPI´s, cálculos necesarios, etc.).</li>
                            <li>Montaje de los primeros <b>informes y dashboards</b> en Biuwer, incluyendo los filtros de análisis interactivos.</li>
                            <li>Configuración de <b>seguridad de acceso</b>, dando de alta a los usuarios clave.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="estoy-interesado" className="container" style={{ padding: '20px 10px' }}>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mb20">Me interesa saber más, ¿qué tengo que hacer?</h2>
                        <h5>Cualquier PYME que quiera solicitar su bono para el programa Kit Digital deberá cumplir los siguientes requisitos:</h5>
                        <ul>
                            <li>Estar al corriente de pagos de las obligaciones tributarias y con la Seguridad Social</li>
                            <li>No superar el límite de ayudas de “mínimis”</li>
                            <li>Pasar el test de Madurez Digital, disponible en&nbsp;
                                <a
                                    href={`https://acelerapyme.gob.es/quieres-conocer-el-grado-de-digitalizacion-de-tu-pyme`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >acelerapyme.gob.es</a>
                            </li>
                            <li>No ser considerada empresa en crisis</li>
                        </ul>
                        <div>
                            <p><b>Biuwer es un producto de Datuary S.L.</b>, registrada como <b>Agente Digitalizador reconocido por Red.es</b> especialista en la solución de <b>Business Intelligence y Analítica</b>.&nbsp;
                                <a
                                    href={`http://www.datuary.com/es/kit-digital-redes`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Aquí tienes toda la información</a>
                            </p>
                            <p>Completa el siguiente formulario y nos ponemos en contacto contigo para estudiar tu proyecto.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container" style={{ padding: '20px 10px' }}>
                <div className="row mb30">
                    <div className="col-12">
                        <iframe title="Estoy interesado - Formulario de registro" src="https://forms.monday.com/forms/embed/254a0db95d6cdc2d77c020d109b369d3?r=use1" width="100%" height="1300px"></iframe>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default KitDigitalPage;